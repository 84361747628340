import React from "react"

import Page from "../../../components/page"
import LineChart, {
  makeChartConfiguration,
} from "../../../components/line-chart"
import Form from "../../../components/form/form"
import FormNumericInput from "../../../components/form/form-numeric-input"
import { SectionLarge } from "../../../components/elements"
import { OneColumnGrid, TwoColumnsGrid } from "../../../components/containers"
import {
  formatCurrency,
  formatPercentage,
  formatNumber,
} from "../../../helpers/functions"

const FIRECalculationResults = ({ data }) => {
  const {
    savingsRate,
    yearlyRetirementExpenses,
    suggestedRetirementCapital,
    targetGrossIncome,
    yearsToRetirement,
    netWorthAtRetirementDate,
  } = data
  return (
    <div>
      <h2>Risultati</h2>
      <p>
        Anni al raggiungimento dell'indipendenza finanziaria:{" "}
        <strong>{formatNumber(yearsToRetirement)}</strong>
      </p>
      <p>
        Tasso di risparmio: <strong>{formatPercentage(savingsRate)}</strong>
      </p>
      <p>
        Spese annuali nette in 'pensione':{" "}
        <strong>{formatCurrency(yearlyRetirementExpenses)}</strong>
      </p>
      <p>
        Introito lordo necessario:{" "}
        <strong>{formatCurrency(targetGrossIncome)}</strong>
      </p>
      <p>
        Capitale minimo suggerito:{" "}
        <strong>{formatCurrency(suggestedRetirementCapital)}</strong>
      </p>
      <p>
        Patrimonio netto al raggiungimento dell'indipendenza finanziaria (alla
        fine dell'anno):{" "}
        <strong>{formatCurrency(netWorthAtRetirementDate)}</strong>
      </p>
    </div>
  )
}

export default class FIRECalculatorPage extends React.Component {
  state = {
    inputs: {
      netWorth: 10000,
      income: 1500,
      currentExpenses: 1000,
      retirementExpenses: 1000,
      cagr: 0.07,
      withdrawalRate: 0.04,
      tax: 0.26,
    },
    outputs: {
      savingsRate: 0,
      yearlyRetirementExpenses: 0,
      suggestedRetirementCapital: 0,
      targetGrossIncome: 0,
      yearsToRetirement: 0,
      netWorthAtRetirementDate: 0,
      withdrawableCapitalDataset: [],
      retirementExpensesDataset: [],
      requiredWithdrawalDataset: [],
      error: null,
    },
  }

  _handleUpdate = event => {
    event.preventDefault()
    const { name, value } = event.target
    let newState = { ...this.state }
    newState.inputs[name] = parseFloat(value)
    this.setState(newState)
  }

  _handleSubmit = event => {
    event.preventDefault()
    this._calculateFIRE()
  }

  _calculateFIRE = () => {
    const { inputs } = this.state
    const {
      netWorth,
      income,
      currentExpenses,
      retirementExpenses,
      cagr,
      withdrawalRate,
      tax,
    } = inputs

    const yearlyIncome = income * 12
    const yearlyExpenses = currentExpenses * 12
    const yearlySavings = yearlyIncome - yearlyExpenses
    const savingsRate = yearlySavings / yearlyIncome

    if (yearlySavings <= 0 && netWorth === 0) {
      this.setState({
        inputs,
        outputs: {
          ...this.state.outputs,
          error: {
            message:
              "Non puoi raggiungere l'indipendenza finanziaria senza risparmiare e senza capitale!",
          },
        },
      })
      return
    }

    const yearlyRetirementExpenses = retirementExpenses * 12
    const targetGrossIncome = yearlyRetirementExpenses / (1 - tax)
    const suggestedRetirementCapital = targetGrossIncome / withdrawalRate

    let yearsToRetirement = 0
    let investedCapital = netWorth
    let netWorthAtRetirementDate = netWorth
    let withdrawableCapital = investedCapital * withdrawalRate

    const withdrawableCapitalDataset = [withdrawableCapital]
    const retirementExpensesDataset = [yearlyRetirementExpenses]
    const requiredWithdrawalDataset = [targetGrossIncome]

    while (
      withdrawableCapital < targetGrossIncome ||
      withdrawableCapitalDataset.length < yearsToRetirement + 6
    ) {
      const shouldUpdateRetirementData = withdrawableCapital < targetGrossIncome
      investedCapital += investedCapital * cagr + yearlySavings
      withdrawableCapital = investedCapital * withdrawalRate

      if (shouldUpdateRetirementData) {
        yearsToRetirement += 1
        netWorthAtRetirementDate = investedCapital
      }

      withdrawableCapitalDataset.push(withdrawableCapital)
      retirementExpensesDataset.push(yearlyRetirementExpenses)
      requiredWithdrawalDataset.push(targetGrossIncome)
    }

    const outputs = {
      error: null,
      savingsRate,
      yearlyRetirementExpenses,
      suggestedRetirementCapital,
      targetGrossIncome,
      yearsToRetirement,
      netWorthAtRetirementDate,
      withdrawableCapitalDataset,
      retirementExpensesDataset,
      requiredWithdrawalDataset,
    }
    this.setState({ ...this.state, outputs })
  }

  render() {
    const { inputs, outputs } = this.state
    const {
      withdrawableCapitalDataset,
      retirementExpensesDataset,
      requiredWithdrawalDataset,
      yearsToRetirement,
      error,
    } = outputs

    const chartData = {
      labels: withdrawableCapitalDataset.map((_, i) => `${i}`),
      datasets: [
        {
          ...makeChartConfiguration({ label: "Capitale Prelevabile" }),
          data: withdrawableCapitalDataset,
        },
        {
          ...makeChartConfiguration({
            label: "Spese in pensione",
            color: "rgb(86, 0, 240)",
            fill: false,
            dashed: true,
            pointRadius: 0,
          }),
          data: retirementExpensesDataset,
        },
        {
          ...makeChartConfiguration({
            label: "Prelievo necessario",
            color: "rgb(86, 120, 240)",
            fill: false,
            pointRadius: 2,
          }),
          data: requiredWithdrawalDataset,
        },
      ],
    }
    const annotations = [
      {
        type: "line",
        mode: "vertical",
        scaleID: "x-axis-0",
        value: yearsToRetirement,
        borderColor: "red",
        borderWidth: 2,
        label: {
          enabled: true,
          position: "center",
          content: "🔥 FIRE Raggiunto!",
        },
      },
    ]
    const canDisplayOutput = !error && withdrawableCapitalDataset.length > 0
    return (
      <Page title="Calcolatore FIRE 🔥">
        <SectionLarge>
          <h1 className="heading">
            Calcolatore FIRE{" "}
            <span role="img" aria-label="FIRE icon">
              🔥
            </span>
          </h1>
          <h2 className="subheading">
            Calcola quanti anni ti serviranno per andare in 'pensione' e quanto
            capitale ti servirà!
          </h2>
          <OneColumnGrid>
            <Form onSubmit={this._handleSubmit}>
              <h2>I tuoi dati</h2>
              <TwoColumnsGrid>
                <div>
                  <FormNumericInput
                    label="Patrimonio Attuale"
                    format="currency"
                    name="netWorth"
                    value={inputs.netWorth}
                    onChange={this._handleUpdate}
                  />
                  <FormNumericInput
                    label="Reddito Corrente Mensile Netto"
                    format="currency"
                    name="income"
                    value={inputs.income}
                    onChange={this._handleUpdate}
                  />
                  <FormNumericInput
                    label="Spese Mensili Correnti"
                    format="currency"
                    name="currentExpenses"
                    value={inputs.currentExpenses}
                    onChange={this._handleUpdate}
                  />
                  <FormNumericInput
                    label="Spese Mensili in Pensione"
                    format="currency"
                    name="retirementExpenses"
                    value={inputs.retirementExpenses}
                    onChange={this._handleUpdate}
                  />
                </div>
                <div>
                  <FormNumericInput
                    label="Tasso di rendimento annuale degli investimenti"
                    format="percentage"
                    name="cagr"
                    value={inputs.cagr}
                    onChange={this._handleUpdate}
                  />
                  <FormNumericInput
                    label="Tasso di prelievo annuale dal capitale investito"
                    format="percentage"
                    name="withdrawalRate"
                    value={inputs.withdrawalRate}
                    onChange={this._handleUpdate}
                  />
                  <FormNumericInput
                    label="Tasse su redditi da capitale"
                    format="percentage"
                    name="tax"
                    value={inputs.tax}
                    onChange={this._handleUpdate}
                  />
                  <div className="text-right">
                    <button className="button-primary" type="submit">
                      Calcola
                    </button>
                  </div>
                </div>
              </TwoColumnsGrid>
            </Form>
            {canDisplayOutput && (
              <OneColumnGrid style={{ marginTop: "64px", gridGap: "64px" }}>
                <FIRECalculationResults data={outputs} />
                <LineChart data={chartData} annotations={annotations} />
              </OneColumnGrid>
            )}
            {error && (
              <OneColumnGrid style={{ marginTop: "64px", gridGap: "64px" }}>
                <p className="text-error text-center">{error.message}</p>
              </OneColumnGrid>
            )}
          </OneColumnGrid>
        </SectionLarge>
      </Page>
    )
  }
}
